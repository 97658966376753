<template>
  <div class="popup-box" v-if="showPopup">
    <img class="bgImg" :src="getImgUrl(curBackgroundUrl)" mode="" @click.stop="close" />
    <!-- 二维码 -->
    <div class="popup-qr-code" v-if="showQrCode" @click.stop="close">
      <div class="popup-qr-code-title">请使用手机扫描二维码，填写样品信息以及联系方式</div>
      <div class="popup-qr-code-title">Please scan the QR code with your mobile phone, fill in the sample information and contact information.</div>
      <img :src="imgUrl" alt="" />
    </div>
    <!-- 第一种 -->
    <div class="popup-content" v-else>
      <img src="../assets/images/clear_car.jpg" alt="" class="popup-content-img1" @click.stop="close" />
      <img src="../assets/images/my_car.jpg" alt="" class="popup-content-img1" @click.stop="close" />
      <img src="../assets/images/add_car.jpg" alt="" class="popup-content-img2" @click.stop="close" />
      <img src="../assets/images/load.jpg" alt="" class="popup-content-img2" @click.stop="openShowQrCode" />
    </div>
  </div>
</template>
<script>
import QRCode from 'qrcode'
export default {
  props: {
    curBackgroundUrl: {
      type: String,
      default: ''
    },
    decorItemID: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showPopup: false,
      showQrCode: false,
      imgUrl: ''
    }
  },
  methods: {
    getImgUrl(url) {
      return require('@/assets/' + url)
    },
    open() {
      this.createQrCode()
      this.showPopup = true
      this.showQrCode = false
    },
    close() {
      this.showPopup = false
      this.showQrCode = false
    },
    openShowQrCode() {
      this.showQrCode = true
    },
    createQrCode() {
      let baseUrl = process.env.VUE_APP_H5_BASE + `/showroom/order?decorItemID=${this.decorItemID}`
      QRCode.toDataURL(baseUrl, {
        errorCorrectionLevel: 'H',
        width: 128,
        height: 128,
        margin: 0,
        scale: 10,
        color: {
          dark: '#000000',
          light: '#ffffff'
        }
      })
        .then(url => {
          console.log(url)
          this.imgUrl = url
        })
        .catch(err => {
          console.error(err)
        })
    }
  }
}
</script>
<style scoped>
.popup-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bgImg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.popup-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2vw;
  box-sizing: border-box;
  background-color: #fff;
}

.popup-content-img1 {
  width: 11.104vw;
  height: 18.518vh;
  cursor: pointer;
}
.popup-content-img2 {
  width: 17.458vw;
  height: 18.518vh;
  cursor: pointer;
}

/* 二维码 */
.popup-qr-code {
  width: 50vw;
  height: 50vh;
  background-color: #fff;
  border: 1px solid #333;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2vh 4vw;
}
.popup-qr-code-title {
  font-size: 16px;
  color: #333;
  margin-bottom: 2vh;
  text-align: center;
}
</style>
