<template>
  <div class="page_container" id="pageA">
    <div class="grid-container" :style="{ color: curColor }">
      <img class="bgImg" :src="getImgUrl(curBackgroundUrl)" mode="" />
      <div class="grid-column1 grid-column">
        <!-- logo -->
        <img class="logo" :style="{ filter: `invert(${curinvert})` }" src="../assets/logo/logo_b.png" mode="" />
      </div>
      <div class="grid-column2 grid-column">
        <div>
          <img class="bigImage" :src="curBigImage" mode="" />
        </div>
      </div>
      <div class="grid-column3 grid-column">
        <div class="grid-column3-box">
          <div class="selector_row" :class="{ dithering: openDithering }">
            <div v-if="currentIndex - 1 < 0" class="placeholder1"></div>
            <div v-if="currentIndex - 2 < 0" class="placeholder2"></div>

            <div
              v-for="(item, index) in decorItems"
              :key="index"
              :class="{
                sm_image_box0: index === currentIndex - 2,
                sm_image_box1: index === currentIndex - 1,
                sm_image_box2: index === currentIndex,
                sm_image_box3: index === currentIndex + 1,
                sm_image_box4: index === currentIndex + 2,
                sm_image_box5: index === currentIndex + 3
              }"
              :style="{
                display:
                  index !== currentIndex - 2 &&
                  index !== currentIndex - 1 &&
                  index !== currentIndex &&
                  index !== currentIndex + 1 &&
                  index !== currentIndex + 2 &&
                  index !== currentIndex + 3
                    ? 'none'
                    : ''
              }"
            >
              <img v-if="decorItems[index].smallImage" :src="decorItems[index].smallImage" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="grid-column4 grid-column">
        <div class="grid-column4-container">
          <div class="en_title">{{ stringToUpper(curName) }}</div>
          <div class="cn_title">{{ curCnName }}</div>
          <div class="long_code">
            {{ curLongCode }}
            <img v-if="decorFiles.length > 0" class="image-icon" :style="{ filter: `invert(${curinvert})` }" src="../assets/image-icon.png" mode="" />
          </div>
          <div class="size">
            <span class="en_style">Repeat</span> / <span style="font-family: DengXian, Arial">尺寸：</span
            ><span class="curSize_font">{{ curSize }}</span>
          </div>
          <div class="describe">
            {{
              curComment
            }}会议传达了全省医保系统党风廉政建设和反腐败工作会议精神。市纪委监委驻市卫健委纪检监察组通报了2024年纪检组工作情况，强调要学深细悟党的创新理论，做实做细政治监督管理，建立健全风腐同查同治机制，深化治理医保基金管理突出问题，压实全面从严治党政治责任，不断强化干部队伍建设，营造好医保系统风清气正的政治生态。会议指出，2024年，全市医保系统在市委、市政府的坚强领导下，在市纪委监委派驻市卫健委纪检监察组的支持监督下，以党纪学习教育为契机，深入贯彻全面从严治党要求，扎实推进党风廉政建设，取得明显成效。
          </div>

          <div class="other_container">
            <div class="buy-now-box">
              <img src="../assets/images/buy.gif" alt="" class="buy-img" @click.stop="openPopup" />
            </div>
            <div class="other_container_box">
              <!-- css仿gif -->
              <div v-if="decorFiles.length === 0" class="gif_container"></div>
              <div v-else class="gif_container2"></div>
              <div class="gif_text">
                <div style="font-family: DengXian, Arial">请转动按钮，查看此花色其他颜色</div>
                <div style="font-family: DengXian, Arial">拍动查看应用案例</div>
                <div style="font-family: Century Gothic, arial">Tune the button to explore various colors.</div>
                <div style="font-family: Century Gothic, arial">Tap to view rendered images.</div>
              </div>
            </div>
          </div>
          <div class="qrcode">
            <div style="margin-bottom: 0.8vw; font-weight: 300; line-height: 1em; font-size: 0.8vw">
              <div style="font-family: DengXian, Arial">手机微信扫描二维码</div>
              <div style="font-family: DengXian, Arial">查看花色详情</div>
              <div style="font-family: Century Gothic, arial">Scan it...</div>
            </div>

            <div class="qrcode_imgbox"></div>
          </div>
          <canvas id="canvas" :style="{ filter: `invert(${curinvert})` }" class="mycanvas"></canvas>
        </div>
      </div>
      <div v-if="showCurDetailImage" class="detailBigImage">
        <img class="" :src="decorFiles[currentRenderingIndex].bigImage" mode="" />
        <div class="curIndexrenderings">{{ currentRenderingIndex + 1 }} / {{ decorFiles.length }}</div>
      </div>
      <curShowToast v-show="isShowToast" :toastValue="toastValue"></curShowToast>
      <popup-button ref="buttonRef" :curBackgroundUrl="curBackgroundUrl" :decorItemID="decorItemID"></popup-button>
    </div>
  </div>
</template>

<script>
import curShowToast from './Toast.vue'
import PopupButton from './PopupButton.vue'
export default {
  components: { curShowToast, PopupButton },
  props: {
    showScreenSaver: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      curBigImageUrl: '', //当前展示花色大图
      colorList: [
        {
          bigImage: 'https://cdn.edesoft.com/xiate/mp/upload/202008/11/177874.jpg',
          smallImage: 'https://cdn.edesoft.com/xiate/mp/upload/202008/11/177874.jpg?x-oss-process=style/small',
          size: '625×1300mm',
          longCode: '14-50079-001',
          msg: '该款花色采用混凝土外观，酷似月球的不规则表面，因而得名。花色用色并不均匀，如同有人使用刮刀和砂浆故意为之。设计师对其原材料做了特殊处理，如刮刀、专门的加工技巧等，使其表面粗糙，该产品较为百搭，用途广，可作为工作台面花色使用。',
          detailImage: 'https://cdn.edesoft.com/xiate/mp/upload/202008/11/177874.jpg',
          renderings: [{ url: 'renderings/img1.jpg' }, { url: 'renderings/img2.jpg' }, { url: 'renderings/img3.jpg' }]
        },
        {
          bigImage: 'https://cdn.edesoft.com/xiate/mp/upload/202008/11/385456.jpg',
          smallImage: 'https://cdn.edesoft.com/xiate/mp/upload/202008/11/385456.jpg?x-oss-process=style/small',
          size: '625×1300mm',
          longCode: '14-50079-002',
          msg: '该款花色采用结构密集的现代设计，有金属感，颇为吸引眼球。设计师同时采用两种不同的圆柱体图案，给产品赋予了现代观感，既有不锈钢的冷塑，也具有极强的可塑性。产品表面采用珠光设计，彰显了产品的高贵和与众不同。',
          detailImage: 'https://cdn.edesoft.com/xiate/mp/upload/202008/11/385456.jpg',
          renderings: [{ url: 'renderings/img1.jpg' }, { url: 'renderings/img2.jpg' }, { url: 'renderings/img3.jpg' }, { url: 'renderings/img5.jpg' }]
        },
        {
          bigImage: 'https://cdn.edesoft.com/xiate/mp/upload/202008/11/195666.jpg',
          smallImage: 'https://cdn.edesoft.com/xiate/mp/upload/202008/11/195666.jpg?x-oss-process=style/small',
          size: '625×1300mm',
          longCode: '14-50079-003',
          msg: ''
        },
        {
          bigImage: 'https://cdn.edesoft.com/xiate/mp/upload/202008/11/210689.jpg',
          smallImage: 'https://cdn.edesoft.com/xiate/mp/upload/202008/11/210689.jpg?x-oss-process=style/small',
          size: '625×1300mm',
          longCode: '14-50079-004',
          msg: ''
        },
        {
          bigImage: 'https://cdn.edesoft.com/xiate/mp/upload/202008/11/489564.jpg',
          smallImage: 'https://cdn.edesoft.com/xiate/mp/upload/202008/11/489564.jpg?x-oss-process=style/small',
          size: '625×1300mm',
          longCode: '14-50079-005',
          msg: ''
        }
      ],
      curBackgroundUrl: '',
      btnRotateUrl: { url: require('../assets/btn_rotate.gif') },
      firstSmImgUrl: '',
      secondSmImgUrl: '',
      thirdSmImgUrl: '',
      fourthSmImgUrl: '',
      fifthSmImgUrl: '',
      curInfo: '',
      backGroundList: [
        { url: 'background/bg1.jpg', color: '#fff', invert: 1 },
        { url: 'background/bg2.jpg', color: '#000000', invert: 0 },
        { url: 'background/bg3.jpg', color: '#000000', invert: 0 },
        { url: 'background/bg4.jpg', color: '#fff', invert: 1 },
        { url: 'background/bg5.jpg', color: '#000000', invert: 0 }
      ],
      curColor: '#fff',
      curinvert: 0,
      frameUrl: require('../assets/frame1.png'),
      curDetailImage: '',
      showCurDetailImage: '',
      currentIndex: 0,
      copyColorList: [],
      toastValue: '',
      isShowToast: false,
      currentRenderings: [],
      currentRenderingIndex: 0,
      isClickRight: false,
      isClickLeft: false,
      timer: null,
      openDithering: false, // 是否开启抖动
      // clientWidth: '' // 窗口宽度
      decorID: '',
      decorItemID: '',
      decorDetail: '',
      curLongCode: '', // 当前longCode
      curName: '', // 当前英文名
      curCnName: '', // 当前中文名
      curBigImage: '', // 当前大图
      curComment: '', // 当前花色说明
      curSize: '', // 当前尺寸
      decorItems: [], // 花色缩略图列表
      decorFiles: [], // 渲染图数组
      keyBoardValue: ''
    }
  },
  created() {
    this.keyDown()

    this.copyColorList = [...this.colorList]
    // 随机生成背景图
    let randomIndex = this.getRandomInt(0, this.backGroundList.length - 1)
    this.curBackgroundUrl = this.backGroundList[randomIndex].url
    this.curColor = this.backGroundList[randomIndex].color
    this.curinvert = this.backGroundList[randomIndex].invert
  },
  computed: {},
  beforeDestroy() {
    this.keyDownReview()
  },
  async mounted() {
    if (!this.decorID || !this.decorItemID) {
      this.loadDefault()
    }
  },
  watch: {},
  methods: {
    openPopup() {
      this.$refs.buttonRef.open()
    },
    stringToUpper(str) {
      return str.toUpperCase()
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    // 开启全屏
    enterFullScreen() {
      this.$emit('setIsFullScreen', true)
    },
    // 退出全屏
    exitFullscreen() {
      this.$emit('setIsFullScreen', false)
    },
    async loadDefault() {
      this.decorID = process.env.VUE_APP_DEFAULT_DECOR_ID // Paris
      this.decorItemID = process.env.VUE_APP_DEFAULT_DECOR_ITEM_ID // 14-57142-003
      await this.getDecorDetail(this.decorID, this.decorItemID)
      await this.getQrCode(this.decorID, this.decorItemID)
    },
    async getQrCode(decorID, decorItemID) {
      var canvas = document.getElementById('canvas')
      let ctx = canvas.getContext('2d')
      canvas.height = 280
      canvas.width = 280
      const res = await this.$api.getQrCode(decorID, decorItemID)
      let blob = new Blob([res], { type: 'image/png' })
      let url = window.URL.createObjectURL(blob)

      let image = new Image()
      image.src = url
      image.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(image, 0, 0)
        if (image.width && image.height) {
          let imageData = ctx.getImageData(0, 0, image.width, image.height)
          // let color = [100, 100, 200]
          for (let i = 0; i < imageData.data.length; i += 4) {
            // 当该像素是白色的，则设置成透明
            if (imageData.data[i] == 255) {
              imageData.data[i] = 0
              imageData.data[i + 1] = 0
              imageData.data[i + 2] = 0
              imageData.data[i + 3] = 0
            }
          }

          ctx.clearRect(0, 0, canvas.width, canvas.height)
          ctx.putImageData(imageData, 15, 15)
        }
      }
    },
    async getDecorDetail(decorID, decorItemID) {
      const res = await this.$api.getDecor(decorID, decorItemID)
      if (res) {
        this.decorDetail = res || {}
        this.curLongCode = res.decorItem.longCode || ''
        this.curCnName = res.cn_name || ''
        this.curName = res.name || ''
        this.curBigImage = res.decorItem.hdImage || res.decorItem.bigImage || ''
        this.curComment = res.comment || ''
        this.curSize = res.decorItem.size || ''
        let newArr = res.decorItems || []
        // 根据longcode排序
        this.decorItems = newArr.sort(this.compare('longCode'))
        this.decorFiles = res.decorFiles || []
      }
      const curIndex = this.decorItems.findIndex(x => x.longCode === this.curLongCode)
      if (curIndex !== -1) {
        this.currentIndex = curIndex
      } else {
        this.currentIndex = 2
      }
      console.log('curIndex', curIndex)
      console.log('结果', res)
    },
    compare(prop) {
      return (obj1, obj2) => {
        var val1 = obj1[prop]
        var val2 = obj2[prop]
        if (val1 < val2) {
          return -1
        } else if (val1 > val2) {
          return 1
        } else {
          return 0
        }
      }
    },
    getImgUrl(url) {
      return require('@/assets/' + url)
    },
    //按钮监听键盘
    async keyDown() {
      //监听键盘按钮
      document.onkeydown = async event => {
        var e = event || window.event
        var keyCode = e.keyCode || e.which

        // console.log('按键', e)
        if (e.key !== 'Shift' && e.key !== 'Enter' && e.key.length === 1) {
          this.keyBoardValue += e.key
        }
        if (e.key === 'Enter') {
          this.enterFullScreen()
          this.showCurDetailImage = false
          // if (!this.checkFull()) {
          //   this.enterFullScreen()
          // }
          // 随机生成背景图
          let randomIndex = this.getRandomInt(0, this.backGroundList.length - 1)
          this.curBackgroundUrl = this.backGroundList[randomIndex].url
          this.curColor = this.backGroundList[randomIndex].color
          this.curinvert = this.backGroundList[randomIndex].invert
          // 最后一个键是enter说明链接已完整
          console.log('完成扫码')
          this.keyBoardValue = this.keyBoardValue.toLowerCase()
          console.log('this.keyBoardValue ', this.keyBoardValue)
          if (this.keyBoardValue.indexOf('decorid') > 0 && this.keyBoardValue.indexOf('decoritemid') > 0) {
            // 需要的参数已存在
            const urlData = this.keyBoardValue.split('?')[1] || this.keyBoardValue.split('？')[1]
            console.log('urlData', urlData)
            this.decorID = Number(urlData.split('&')[0].split('=')[1])
            this.decorItemID = Number(urlData.split('&')[1].split('=')[1])
            console.log('this.decorID:', this.decorID)
            console.log('this.decorItemID:', this.decorItemID)
            this.keyBoardValue = ''
            await this.getDecorDetail(this.decorID, this.decorItemID)
            await this.getQrCode(this.decorID, this.decorItemID)
          }
        }

        this.$emit('getKeyCode', keyCode)
        switch (keyCode) {
          case 8: // backspace 返回待机也页面
            this.showCurDetailImage = false
            this.returnScreenSaver()
            break
          case 37: //<-键
            if (this.showScreenSaver) return
            this.keyLeft()
            break
          case 38: // 上箭头键-切换背景
            if (this.showScreenSaver) return
            this.changeBackground()
            break
          case 39: //->键
            if (this.showScreenSaver) return
            this.keyRight()
            break
          case 40: // 下键
            if (this.showScreenSaver) return
            this.openDetailImg()
            break
          case 27: // esc
            this.closeDetailImg()
            // if (this.checkFull()) {
            //   this.exitFullscreen()
            // }
            break
          case 112:
            // 开启/关闭全屏
            // this.checkFullScreen()
            break
          default:
            break
        }
      }
    },
    closeDetailImg() {
      if (this.curDetailImage) {
        this.showCurDetailImage = false
      }
    },
    returnScreenSaver() {
      this.$emit('returnScreenSaver')
    },
    // 打开效果图
    openDetailImg() {
      this.currentRenderingIndex = 0
      if (this.decorFiles.length === 0) return
      if (this.decorFiles.length > 0 && this.decorFiles[this.currentRenderingIndex].bigImage) {
        this.showCurDetailImage = !this.showCurDetailImage
      }
    },
    changeBackground() {
      let curIndex
      this.backGroundList.forEach((item, index) => {
        if (item.url === this.curBackgroundUrl) {
          curIndex = index
        }
      })

      if (curIndex === this.backGroundList.length - 1) {
        this.curBackgroundUrl = this.backGroundList[0].url
        this.curColor = this.backGroundList[0].color
        this.curinvert = this.backGroundList[0].invert
      } else {
        this.curBackgroundUrl = this.backGroundList[curIndex + 1].url
        this.curColor = this.backGroundList[curIndex + 1].color
        this.curinvert = this.backGroundList[curIndex + 1].invert
      }
    },

    async keyRight() {
      console.log('右键')
      // 判断效果图是否打开,效果图打开时只能左右切换效果图
      if (this.showCurDetailImage && this.decorFiles.length > 0) {
        // 切换效果图
        if (this.currentRenderingIndex < this.decorFiles.length - 1) {
          this.currentRenderingIndex++
        }

        return
      }
      if (this.currentIndex === this.decorItems.length - 1) {
        this.openDithering = true
        setTimeout(() => {
          this.openDithering = false
        }, 300)
        return
      } else {
        if (this.currentIndex < this.decorItems.length - 1) {
          this.currentIndex++
        }
      }

      this.curBigImage = this.decorItems[this.currentIndex].hdImage || this.decorItems[this.currentIndex].bigImage
      this.curLongCode = this.decorItems[this.currentIndex].longCode
      this.curSize = this.decorItems[this.currentIndex].size
      this.decorItemID = this.decorItems[this.currentIndex].id
      // await this.getDecorDetail(this.decorID, this.decorItems[this.currentIndex].id)
      await this.getQrCode(this.decorID, this.decorItemID)
    },
    async keyLeft() {
      console.log('左键')
      // 判断效果图是否打开,效果图打开时只能左右切换效果图
      if (this.showCurDetailImage && this.decorFiles.length > 0) {
        // 切换效果图
        if (this.currentRenderingIndex > 0) {
          this.currentRenderingIndex--
        }
        return
      }
      this.currentIndex--
      if (this.currentIndex < 0) {
        this.openDithering = true
        setTimeout(() => {
          this.openDithering = false
        }, 300)
        this.currentIndex = 0
      }
      this.curBigImage = this.decorItems[this.currentIndex].hdImage || this.decorItems[this.currentIndex].bigImage

      this.curLongCode = this.decorItems[this.currentIndex].longCode
      this.curSize = this.decorItems[this.currentIndex].size
      this.decorItemID = this.decorItems[this.currentIndex].id
      // await this.getDecorDetail(this.decorID, this.decorItems[this.currentIndex].id)
      await this.getQrCode(this.decorID, this.decorItemID)
    },
    //按键恢复
    keyDownReview() {
      document.onkeydown = function (event) {
        var e = event || window.event
        e.returnValue = true
      }
    }
  }
}
</script>
<style scoped>
.curSize_font {
  font-family: Century Gothic, arial;
}
.placeholder1 {
  width: 3.8vw;
  height: 3.8vw;
  margin-right: -6px;
}
.placeholder0 {
  width: 2.8vw;
  height: 2.8vw;
  margin-right: -8px;
}
.mycanvas {
  position: absolute;
  width: 8vw;
  height: 8vw;
  z-index: 99;
  right: -0.9vw;
  bottom: -0.8vw;
}
.fade {
  /* 2->1时第一个要消失 */
  animation: fade 0.6s 1;
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.shrink_left {
  /* 2->1向左缩小动画 */
  animation: shrinkLeft 0.5s 1;
}
@keyframes shrinkLeft {
  0% {
    z-index: 5;
    width: 5.5vw;
    height: 5.5vw;
    transform: translate(0px, 0px);
  }
  100% {
    z-index: 4;
    transform: translate(-40px, 0px);
    width: 4vw;
    height: 4vw;
  }
}
.larger_right {
  /* 1-2向右放大 */
  animation: largerRight 0.5s 1;
}
.zoom_right {
  /* 2->3向右缩小 */
  animation: zoomRight 0.5s 1;
}
.zoom_right1 {
  /* 3->4向右缩小 */
  animation: zoomRight1 0.5s 1;
}
.zoom_right2 {
  /* 4->5向右缩小 */
  animation: zoomRight2 0.5s 1;
}
@keyframes zoomRight2 {
  0% {
    opacity: 0.7;
    width: 3vw;
    height: 3vw;
    transform: translate(0px, 0px);
    z-index: 3;
    outline: 0;
  }
  100% {
    opacity: 0.4;
    width: 2.3vw;
    height: 2.3vw;
    position: relative;
    z-index: 2;
    margin-left: -10px;
    transform: translate(30px, 0px);
  }
}
@keyframes zoomRight1 {
  0% {
    transform: translate(0px, 0px);
    width: 4vw;
    height: 4vw;
    z-index: 4;
    outline: 1px solid #c6c6c3;
    border-radius: 10px;
  }
  100% {
    opacity: 0.7;
    width: 3vw;
    height: 3vw;
    transform: translate(30px, 0px);
    z-index: 3;
    outline: 0;
    border-radius: 6px;
  }
}
@keyframes zoomRight {
  0% {
    transform: translate(0px, 0px);
    width: 5.5vw;
    height: 5.5vw;
    z-index: 5;
  }
  100% {
    width: 4vw;
    height: 4vw;
    transform: translate(40px, 0px);
    z-index: 4;
  }
}
.larger_left {
  /* 3->2向左放大动画 */
  animation: largerLeft 0.5s 1;
}
.larger_left1 {
  /* 4->3向左放大动画 */
  animation: largerLeft1 0.5s 1;
}
.larger_left2 {
  /* 5->4向左放大动画 */
  animation: largerLeft2 0.5s 1;
}
@keyframes largerRight {
  0% {
    width: 4vw;
    height: 4vw;
    outline: 1px solid #c6c6c3;
    margin-right: -6px;
    border-radius: 10px;
    box-shadow: 0px 6px 6px 3px rgb(0 0 0 / 30%);
    transform: translate(0px, 0px);
  }
  100% {
    width: 5.5vw;
    height: 5.5vw;
    outline: 1px solid #c6c6c3;
    position: relative;
    z-index: 5;
    border-radius: 12px;
    box-shadow: 0px 6px 6px 3px rgb(0 0 0 / 30%);
    transform: translate(50px, 0px);
  }
}
@keyframes largerLeft1 {
  0% {
    opacity: 0.7;
    width: 3vw;
    height: 3vw;
    transform: translate(0px, 0px);
  }
  100% {
    width: 4vw;
    height: 4vw;
    opacity: 1;
    transform: translate(-40px, 0px);
  }
}
@keyframes largerLeft2 {
  0% {
    opacity: 0.4;
    width: 2.3vw;
    height: 2.3vw;
    transform: translate(0px, 0px);
  }
  100% {
    opacity: 0.7;
    width: 3vw;
    height: 3vw;
    transform: translate(-40px, 0px);
  }
}
@keyframes largerLeft {
  0% {
    width: 4vw;
    height: 4vw;
    transform: translate(0px, 0px);
  }
  100% {
    width: 5.5vw;
    height: 5.5vw;
    transform: translate(-40px, 0px);
  }
}
.curIndexrenderings {
  position: absolute;
  top: 5px;
  z-index: 10;
  font-size: 14px;
  border-radius: 18px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 8px;
  color: #fff;
}
.top_inset_shadow {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  box-shadow: inset 0px 7px 8px rgba(255, 255, 255, 0.35);
}
.detailBigImage img {
  display: block;
  object-fit: cover;
  /* width: 600px; */
  height: 80vh;
  position: relative;
  z-index: 10;
  box-shadow: 0px 0px 20px #ffffff;
  border-radius: 20px;
}
.detailBigImage {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}
.en_style {
  font-family: Century Gothic;
}
.frame {
  display: block;
  width: 100px;
}

.bgImg {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.qrcode_img {
  height: 100%;
  width: 100%;
}
.qrcode_imgbox {
  height: 6vw;
  width: 4.8vw;
}
.qrcode {
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 0;
}
.qrcode div div {
  margin-bottom: 6px;
}

.gif_img {
  width: 100px;
}
.gif_text div {
  line-height: 1.4em;
  font-size: 0.8vw;
  font-weight: 100;
}
.other_container_box {
  display: grid;
  grid-template-columns: 7.2vw minmax(270px, 25vw);
  align-items: center;
}
.gif_container {
  width: 100px;
  height: 75px;
  animation: changeBg 2.5s infinite;
  animation-timing-function: step-start;
  position: relative;
  z-index: 1;
  background-size: 100% 100%;
}
@keyframes changeBg {
  50% {
    background-image: url('../assets/frame1.png');
  }
  100% {
    background-image: url('../assets/frame2.png');
  }
}
.gif_container1 {
  width: 100px;
  height: 75px;
  animation: changeBg1 2s infinite;
  animation-timing-function: step-start;
  position: relative;
  z-index: 1;
  background-size: 100% 100%;
}
@keyframes changeBg1 {
  50% {
    background-image: url('../assets/frame4.png');
  }
  100% {
    background-image: url('../assets/frame3.png');
  }
}
.gif_container2 {
  width: 7vw;
  height: 5.25vw;
  margin-left: -1vw;
  animation: changeBg2 8s infinite;
  animation-timing-function: step-start;
  position: relative;
  z-index: 1;
  background-size: 100% 100%;
}
@keyframes changeBg2 {
  15% {
    background-image: url('../assets/frame1.png');
  }
  30% {
    background-image: url('../assets/frame2.png');
  }
  45% {
    background-image: url('../assets/frame1.png');
  }
  60% {
    background-image: url('../assets/frame2.png');
  }
  70% {
    background-image: url('../assets/frame3.png');
  }
  85% {
    background-image: url('../assets/frame4.png');
  }
  100% {
    background-image: url('../assets/frame3.png');
  }
}
.other_container {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}
.describe {
  margin-top: 8vh;
  font-size: 1.3vw;
  line-height: 2vw;
  max-width: 34.4vw;
  letter-spacing: 1.4px;
  font-weight: 100;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* 能够显示的行数，超出部分用...表示*/
  -webkit-box-orient: vertical;
  font-family: DengXian, Arial;
}
.size {
  font-size: 1.6vw;
  margin-top: 7vh;
  font-weight: 300;
}

.long_code {
  font-size: 1.7vw;
  font-weight: 600;
  margin-top: 2.5vh;
  display: flex;
  align-items: center;
  height: 50px;
  font-family: Century Gothic, arial;
  font-weight: bold;
}
.image-icon {
  width: 2.4vw;
  height: 2.4vw;
  margin-left: 20px;
}
.en_title {
  font-size: 4.8vw;
  font-weight: bold;
  font-family: Century Gothic, arial;
}
.cn_title {
  font-size: 3.3vw;
  font-family: DengXian, Arial;
  font-weight: bold;
}
.grid-column4-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.selector_row div img {
  width: 100%;
  height: 100%;
}
.selector_row div {
  overflow: hidden;
  transition: all 0.2s;
}
.sm_image_box1 {
  position: relative;
  z-index: 1;
  width: 3.8vw;
  height: 3.8vw;
  outline: 1px solid #c6c6c3;
  margin-right: -6px;
  border-radius: 10px;
  box-shadow: 0px 6px 6px 3px rgba(0, 0, 0, 0.3);
}
.sm_image_box1_Placeholder {
  width: 4vw;
  height: 4vw;
  margin-right: -6px;
}

.sm_image_box2 {
  width: 5.3vw;
  height: 5.3vw;
  min-height: 60px;
  min-width: 60px;
  outline: 1px solid #c6c6c3;

  position: relative;
  z-index: 5;
  border-radius: 12px;
  box-shadow: 0px 6px 6px 3px rgba(0, 0, 0, 0.3);
}
.sm_image_box2_Placeholder {
  width: 5.3vw;
  height: 5.3vw;
}

.sm_image_box3 {
  width: 3.8vw;
  height: 3.8vw;
  min-height: 43.49px;
  min-width: 43.49px;
  outline: 1px solid #c6c6c3;
  box-shadow: 0px 6px 6px 3px rgba(0, 0, 0, 0.3);

  margin-left: -6px;
  position: relative;
  z-index: 4;
  border-radius: 10px;
}
.sm_image_box3_Placeholder {
  width: 3.8vw;
  height: 3.8vw;
  margin-left: -6px;
}
.sm_image_box0 {
  opacity: 0.7;
  width: 2.8vw;
  height: 2.8vw;
  min-height: 32.61px;
  min-width: 32.61px;
  position: relative;
  z-index: 0;
  margin-right: -8px;
  border-radius: 6px;
  box-shadow: 0px 6px 6px 3px rgba(0, 0, 0, 0.3);
}
.sm_image_box4 {
  opacity: 0.7;
  width: 2.8vw;
  height: 2.8vw;
  min-height: 32.61px;
  min-width: 32.61px;
  position: relative;
  z-index: 3;
  margin-left: -8px;
  border-radius: 6px;
  box-shadow: 0px 6px 6px 3px rgba(0, 0, 0, 0.3);
}
.sm_image_box4_Placeholder {
  width: 2.8vw;
  height: 2.8vw;
  margin-left: -8px;
}

.sm_image_box5 {
  opacity: 0.4;
  width: 2.1vw;
  height: 2.1vw;
  min-height: 25px;
  min-width: 25px;
  position: relative;
  z-index: 2;
  margin-left: -10px;
  border-radius: 6px;
  box-shadow: 0px 6px 6px 3px rgba(0, 0, 0, 0.3);
}
.sm_image_box5_Placeholder {
  width: 2.1vw;
  height: 2.1vw;
  margin-left: -10px;
}
.selector_row {
  display: flex;
  align-items: center;
  /* height: 110px; */
  height: 5.5vw;
  /* width: 16.72vw; */
}
.dithering {
  animation: dithering 0.5s 1;
}
@keyframes dithering {
  0% {
    translate: 0;
  }
  25% {
    translate: 15px;
  }
  /* 50% {
    translate: 0;
  } */
  75% {
    translate: -15px;
  }
  100% {
    translate: 0;
  }
}
.grid-column3-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.grid-column1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  transform: rotate(270deg);
  transform-origin: center;
  width: 31.1vw;
}
.grid-column2 div {
  width: 100%;
  /* overflow: hidden;
  aspect-ratio: 1684/3400;
  border: 1px solid saddlebrown;
  border-radius: 2.5vw; */
  position: relative;
}
.bigImage {
  width: 100%;
  /* height: 100px; */
  aspect-ratio: 1684/3400;
  border-radius: 2.4vw;
  object-fit: cover;
  object-position: 0% 0%;
}
.grid-column {
  /* border: 1px solid; */
  padding: 5.1vh 0px 7.2vh 0px;
}
.grid-column2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.grid-container {
  color: black;
  font-size: 10px;
  display: grid;
  grid-template-columns: 7.54vw 24.4vw 24.6vw 39vw auto;
  height: 100vh;
  /* overflow: hidden; */
  /* background-image: url('../assets/background/bg3.jpg'); */
  background-size: 100% 100%;
  user-select: none;
}
/* @media (min-width: 1040px) {
  .gif_text div {
    line-height: 20px;
    font-size: 6px;
    font-weight: 100;
  }
} */

.buy-now-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2vh;
}
.buy-img {
  width: auto;
  aspect-ratio: 3543/1556;
  height: 14.407vh;
  cursor: pointer;
}
</style>
